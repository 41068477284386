import { Accessor, Show } from "solid-js";
import { A, Action } from "@solidjs/router";
import { createTiptapEditor } from "solid-tiptap";
import StarterKit from "@tiptap/starter-kit";
import Placeholder from "@tiptap/extension-placeholder";
import Image from "@tiptap/extension-image";
import { useAppState } from "~/AppContext";
import ToolbarContents from "./Toolbar";
import { ChevronLeft, Check } from "./icons";
import { Article } from "~/types";

export default function ArticleForm({
  data,
  action,
}: {
  data: Accessor<Article | undefined>;
  action: Action<[formData: FormData], void>;
}) {
  let ref!: HTMLDivElement;
  let cRef!: HTMLTextAreaElement;

  const { t } = useAppState();
  const editor = createTiptapEditor<HTMLDivElement>(() => ({
    element: ref!,
    extensions: [
      StarterKit,
      Placeholder.configure({
        placeholder: t("content"),
        emptyNodeClass:
          "first:before:text-gray-400 first:before:content-[attr(data-placeholder)]",
      }),
      Image,
    ],
    content: data()?.content || "<p></p>",
    editorProps: {
      attributes: {
        class: "relative py-2 px-4 border-none outline-0 max-w-none",
      },
    },
  }));

  const preSubmit = (e: SubmitEvent) => {
    cRef.value = editor()?.getHTML() || "";
  };

  return (
    <form
      class="max-w-screen-lg mx-auto flex flex-col grow w-full relative"
      action={action}
      method="post"
      onSubmit={preSubmit}
    >
      <div class="py-1 flex justify-between items-center relative md:hidden ">
        <div class="w-20 text-left mx-1 px-1">
          <A href="/dash/articles" class="font-semibold">
            <ChevronLeft size={6} />
          </A>
        </div>
        <div class="text-lg font-semibold text-center grow text-nowrap truncate ...">
          {t("dash.writing").toUpperCase()}
        </div>
        <div class="w-20 text-right h-6 px-1 mx-1">
          <button
            type="submit"
            title={t("save")}
            class="font-semibold hover:bg-gray-100   "
          >
            <Check size={6} />
          </button>
        </div>
      </div>
      <main class="grow flex flex-col prose dark:prose-invert max-w-none relative">
        <input hidden name="id" value={data()?.id} />
        <div>
          <input
            type="text"
            id="essence"
            class="dark:bg-gray-900 bg-white w-full my-2 py-1 px-4 rounded-sm 
            placeholder:font-bold font-extrabold dark:placeholder:text-gray-400 border-none ring-0 focus:ring-0 text-4xl"
            placeholder={t("titleLabel")}
            required
            name="title"
            value={data()?.title || ""}
          />
        </div>

        <textarea ref={cRef} hidden name="content" />
        <Show when={editor()} keyed>
          {(instance) => (
            <ToolbarContents
              editor={instance}
              class="text-xl w-full overflow-x-auto order-last bg-gray-100 md:bg-inherit  md:static md:order-none relative px-4"
              iconClass="h-8 w-8"
            />
          )}
        </Show>
        <div class="relative grow overflow-y-auto">
          <div id="editor" ref={ref} class="absolute md:relative" />
        </div>

        <div class="px-4">
          <button
            type="submit"
            class="hidden md:inline-flex items-center px-8 py-2 font-medium text-center rounded-sm outline outline-1 hover:dark:bg-gray-800 hover:bg-gray-100"
          >
            {t("save")}
          </button>
        </div>
      </main>
    </form>
  );
}
